import { NaprodajInterface } from "./arrayInterface"

const NaprodajArr: Array<NaprodajInterface> = [
    {
        src: '/assets/naprodaj/Pomlad_digitalna_print.png',
        title: 'Pomlad je tu',
        description: 'Print',
        medium: 'Print',
        dimensions: 'A4, A5',
        price: 'A5: 10 €, A4: 15 €'
    },
    {
        src: '/assets/naprodaj/Mormon_digitalna.png',
        title: 'Atlantski mormon',
        sciName: 'Fratercula arctica',
        description: 'Printi atlantskega mormona',
        medium: 'Print',
        dimensions: 'A4, A5',
        price: 'A5: 10 €, A4: 15 €'
    },
    {
        src: '/assets/naprodaj/Avrikelj_original.png',
        title: 'Avrikelj 2023',
        sciName: 'Primula auricula',
        description: 'Avrikelj. Medij akvarel in ink',
        medium: 'Akvarel in ink',
        dimensions: '22 x 30 cm',
        price: '250 €'
    },
    {
        src: '/assets/naprodaj/Kranjska_lilija_original.png',
        title: 'Kranjska lilija 2023',
        sciName: 'Lilium carniolicum',
        description: 'Kranjska lilija. Medij akvarel in ink',
        medium: 'Akvarel in ink',
        dimensions: '23 x 30 cm',
        price: '300 €'
    },
    {
        src: '/assets/naprodaj/Svisc_spoml_original.png',
        title: 'Spomladanski svišč 2023',
        sciName: 'Gentiana verna',
        description: 'Spomladanski svišč. Medij akvarel in ink',
        medium: 'Akvarel in ink',
        dimensions: '18 x 25 cm',
        price: '200 €'
    },
    {
        src: '/assets/naprodaj/Iris_original_akvaink.png',
        title: 'Ilirska perunika 2023',
        sciName: 'Iris illyrica',
        description: 'Ilirska perunika. Medij akvarel in ink',
        medium: 'Akvarel in ink',
        dimensions: '18 x 25 cm',
        price: '300 €'
    },
    {
        src: '/assets/naprodaj/Iris_original.png',
        title: 'Ilirska perunika 2017',
        sciName: 'Iris illyrica',
        description: 'Neodprti cvetovi ilirske perunike.',
        medium: 'Akvarel',
        dimensions: '17.7 x 25.8 cm',
        price: '300 €',
    },
    {
        src: '/assets/naprodaj/Oljčna_vejica.png',
        title: 'Oljčna vejica 2021',
        sciName: 'Olea europaea',
        description: 'Oljka',
        medium: 'Suhi pasteli',
        dimensions: '14.5 x 19 cm',
        price: '150 €',
    },
    {
        src: '/assets/naprodaj/Monarh_nalepka.png',
        title: 'Monarh metulj',
        sciName: 'Danaus plexippus',
        description: 'Nalepka, prozorna',
        medium: 'Nalepka, prozorna',
        dimensions: '7.62 x 4.3 cm',
        price: '3 €',
    },
    {
        src: '/assets/naprodaj/Cebela_nalepka.png',
        title: 'Čebela na cvetu',
        description: 'Nalepka',
        medium: 'Nalepka',
        dimensions: 'Premer: 5 cm',
        price: '3 €',
    },
    {
        src: '/assets/naprodaj/Tascica_nalepka.png',
        title: 'Taščica',
        description: 'Nalepka',
        medium: 'Nalepka',
        dimensions: 'Premer: 5 cm',
        price: '3 €',
    },
    {
        src: '/assets/naprodaj/Botanika_magnet.png',
        title: 'Botanika',
        description: 'Magnet',
        medium: 'Magnet',
        dimensions: 'Premer: 5.5 cm',
        price: '3 €',
    },
    {
        src: '/assets/naprodaj/Iris_magnet.png',
        title: 'Iris',
        description: 'Magnet',
        medium: 'Magnet',
        dimensions: '5.5 x 7.5 cm',
        price: '3 €',
    },
]

export default NaprodajArr
